<template>
  <v-container>
    <p>
      Url de diffusion:
      {{ urlDiffusion }}
    </p>
    <div style="height: 700px; max-width: 1080px">
      <div>
        <div id="magnetodiv" />
      </div>
    </div>

    <chat
      :chaine="chaineBackstage"
      :formateur="mode === 'responsable'"
      :user-info="$store.state.userInfo"
      :titre="'Questions liées à la présentation'"
    />

    <gestion-question
      v-if="mode === 'responsable'"
      :chaine="chaineConference"
      :user-info="$store.state.userInfo"
    />
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import Chat from '../../agora/Chat'
  import GestionQuestion from '../../agora/GestionQuestion'
  export default {
    components: {
      Chat,
      GestionQuestion,
    },
    data: () => ({
      conference: undefined,
      urlPresentationClient: undefined,
      mode: '',
    }),
    computed: {
      chaineConference () {
        const chaine = 'ORKA-C-' + this.$route.params.idConference
        return chaine
      },
      chaineBackstage () {
        const chaine = 'ORKA-C-' + this.$route.params.idConference + '-Backstage'
        return chaine
      },
      urlDiffusion () {
        return `https://d1fj07sw7cr824.cloudfront.net/hls/${process.env.VUE_APP_CONTEXTE}_${this.chaineConference}.m3u8`
      },
    },

    created () {
      setTimeout(() => {
        console.log(`${process.env.VUE_APP_CONTEXTE}_${this.chaineConference}`)
        window.initMagneto(
          '#magnetodiv',
          process.env.VUE_APP_CONTEXTE,
          this.chaineConference,
          '',
        )
      }, 2000)
    },

    mounted () {
      this.init()
      if (document.getElementById('magnetoscript')) return
      const script = document.createElement('script')
      script.setAttribute(
        'src',
        'https://magneto.connexence.com/magneto/js/magneto.js',
      )
      script.setAttribute('id', 'magnetoscript')
      document.head.appendChild(script)

      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = 'https://magneto.connexence.com/magneto/js/magneto.css'
      link.media = 'all'
      document.head.appendChild(link)
    },

    methods: {
      init () {
        restApiService
          .get('/api/webUser/infoConference/' + this.$route.params.idConference)
          .then(result => {
            this.conference = result.data.conference
            this.urlPresentationClient = result.data.urlPresentationClient
            if (result.data.estResponsable) {
              this.mode = 'responsable'
            } else {
              this.mode = 'participant'
            }
          })
          .catch(error => alert(error))
      },
    },
  }
</script>

<style>
#magnetodiv {
  width: 100%;
  min-width: 300px;
  height: 400px;
}
</style>
